import { useTranslation } from 'react-i18next';
import { getEnvOrThrow, useMonkAppState } from '@monkvision/common';
import styles from './InspectionCompletePage.module.css';

const getEnv = () => {
  const env = getEnvOrThrow('REACT_APP_ENVIRONMENT');
  switch (env) {
    case 'backend-staging-qa':
      return 'dashboard-backend-qa.tesla.staging.dev.monk.ai';
    case 'staging':
      return 'dashboard.tesla.staging.dev.monk.ai';
    case 'preview':
      return 'dashboard.tesla.preview.monk.ai';
    default:
      return 'dashboard.tesla.preview.monk.ai';
  }
};

export function InspectionCompletePage() {
  const { inspectionId } = useMonkAppState();
  const { t } = useTranslation();

  return (
    <div className={styles['container']}>
      <div className={styles['label']}>{t('inspection-complete.thank-message')}</div>
      <div className={styles['link']}>{`https://${getEnv()}/?i=${inspectionId}`}</div>
    </div>
  );
}
