import { useMonkAppState, useMonkState } from '@monkvision/common';
import { useCallback, useMemo } from 'react';
import { useMonkApi } from '@monkvision/network';
import { useMonitoring } from '@monkvision/monitoring';
import { TeslaAdditionalData, TeslaInspectionStatus } from './types';

export function useUpdateTeslaAdditionalData() {
  const { config, authToken, inspectionId } = useMonkAppState({
    requireInspection: true,
  });
  const { updateAdditionalData } = useMonkApi({
    authToken,
    apiDomain: config.apiDomain,
    thumbnailDomain: config.thumbnailDomain,
  });
  const {
    state: { inspections },
  } = useMonkState();
  const inspection = useMemo(() => {
    const currentInspection = inspections.find((i) => i.id === inspectionId);
    if (!currentInspection) {
      return undefined;
    }
    return currentInspection;
  }, [inspections, inspectionId]);
  const { handleError } = useMonitoring();

  const updateTeslaInspection = useCallback(
    async (additionalData: TeslaAdditionalData) => {
      try {
        await updateAdditionalData({
          id: inspectionId,
          callback: (existingData) => ({ ...existingData, ...additionalData }),
        });
      } catch (err) {
        handleError(err);
      }
    },
    [updateAdditionalData, handleError, inspectionId],
  );

  const handlePictureTaken = useCallback(() => {
    if (!inspection) {
      throw new Error('Unable to find current inspection in the local Monk State');
    }
    if (inspection.images.length === 0) {
      const now = new Date().toISOString();
      const additionalData: TeslaAdditionalData = {
        tesla_status: TeslaInspectionStatus.ONGOING_CAPTURE,
        tesla_last_updated: now,
        tesla_inspection_start: now,
      };
      return updateTeslaInspection(additionalData);
    }
    return Promise.resolve();
  }, [inspection, updateTeslaInspection]);

  const handleInspectionCompleted = useCallback(async () => {
    const now = new Date().toISOString();
    const additionalData: TeslaAdditionalData = {
      tesla_status: TeslaInspectionStatus.READY_FOR_REVIEW,
      tesla_last_updated: now,
      tesla_inspection_end: now,
    };
    return updateTeslaInspection(additionalData);
  }, [updateTeslaInspection]);

  return { handlePictureTaken, handleInspectionCompleted };
}
