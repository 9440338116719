import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMonkAppState } from '@monkvision/common';
import { PhotoCapture } from '@monkvision/inspection-capture-web';
import { useNavigate } from 'react-router-dom';
import { useMonitoring } from '@monkvision/monitoring';
import styles from './PhotoCapturePage.module.css';
import { Page } from '../pages';
import { useUpdateTeslaAdditionalData } from '../../hooks';

export function PhotoCapturePage() {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const { config, authToken, inspectionId, getCurrentSights } = useMonkAppState({
    requireInspection: true,
  });
  const currentSights = useMemo(() => getCurrentSights(), [getCurrentSights]);
  const { handlePictureTaken, handleInspectionCompleted } = useUpdateTeslaAdditionalData();
  const { handleError } = useMonitoring();

  const handleComplete = () => {
    handleInspectionCompleted().catch(handleError);
    navigate(Page.INSPECTION_COMPLETE);
  };

  return (
    <div className={styles['container']}>
      <PhotoCapture
        {...config}
        apiConfig={{
          authToken,
          apiDomain: config.apiDomain,
          thumbnailDomain: config.thumbnailDomain,
        }}
        inspectionId={inspectionId}
        sights={currentSights}
        onComplete={handleComplete}
        onPictureTaken={handlePictureTaken}
        lang={i18n.language}
      />
    </div>
  );
}
